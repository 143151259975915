body[data-theme="dark"] {  

   @import '../src/scss/dark-slider.scss';

   .slider-tab {
      background-color: #2e3240;
   }

   .title-styles {
      color: white;
   }

   .header-icon {
      color: #3C342C;
      opacity: 0.8;
   }

   .slider-image {
      border: 5px solid #050e29;
   }

   .language {
      background-color: #494949;
   }

   .link-href {
      color: white;
   }

   .project-date {
      background-color: #918e97;
      color: white;
   }

   header {
      background-color: #081c3da4;
      h1 {
         color: white;
      }
   }

   #about {
      background: linear-gradient(to bottom, #183d7c,#02204e);
      h1 span {
         color: white;
      }
      .polaroid span {
         background:  #050e29;
      }
      .card {
         background: rgba(255, 255, 255, 0.994);
         color: black;
         font-weight: 500;
         border-radius: 10px;
      }
   }

   #art {
      background: linear-gradient(to bottom, #02204e,#021c47);
      .section-title {
         color: white !important;
      }
      .foto div {
         background: #050e29;
      }
      .project-title-settings {
         color: white;
      }
   }

   #resume {
      // filter: brightness(90%);
      background: linear-gradient(to bottom, #02204e,#021c47);
      .section-title {
         color: white !important;
      }

      .experience-badge {
         background: #021c47 !important;
         color: white;
         font-size: 14px;
         font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }

      .main-badge {
         background: #0877d8 !important;
         font-size: 14px;
         font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }

      .vertical-timeline-element-date {
         color: white;
         font-size: 12px;
      }

      .vertical-timeline-element-icon {
         background: #0057a3 !important;
      }
      @media only screen and (max-width: 1069px) { 
         .vertical-timeline-element-date {
            color: #040404 !important;;
         }
      }
   }

   .modal-inside .modal-content {
      background: rgba(255, 255, 255, 0.926);
      color: rgba(255, 255, 255, 0.316);
   }

   .close-icon {
      color: rgba(255, 255, 255, 0.926);
   }
}